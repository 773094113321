import store from '@state/store'

const intervalsId = {}
const defaultIntervalTime = 10000 // 2 minutos => 2m * 60s * 1000ms

// ToDo Change it for Long Pulling

export default {
  start(dispatchName, namespace, data, customTimeInterval) {
    namespace = namespace || 'default'

    if (intervalsId[dispatchName] && intervalsId[dispatchName][namespace])
      return false

    if (!intervalsId[dispatchName]) intervalsId[dispatchName] = {}

    customTimeInterval = customTimeInterval || defaultIntervalTime

    store.dispatch(dispatchName, { namespace, data })
    intervalsId[dispatchName][namespace] = setInterval(function() {
      store.dispatch(dispatchName, { namespace, data })
    }, customTimeInterval)

    return true
  },

  stop(dispatchName) {
    if (!intervalsId[dispatchName]) return false

    clearInterval(intervalsId[dispatchName])
    return delete intervalsId[dispatchName]
  },

  sequence(dispatchName, sequence, data) {
    sequence.forEach((v) => {
      setTimeout(() => {
        store.dispatch(dispatchName, data)
      }, v * 1000)
    })
  },
}
