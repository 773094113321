<template>
  <div>
    <k-analize v-if="checkStepRegistration(6)"></k-analize>
    <k-working v-if="checkStepRegistration(7)"></k-working>
  </div>
</template>
<script>
import longPulling from '@src/services/long-pulling'
import { authMethods, authComputed } from '@state/helpers'

const kAnalize = () => import('./Analize.vue')
const kWorking = () => import('./Working.vue')

export default {
  name: 'registering-progress-admin',
  components: {
    kAnalize,
    kWorking,
  },
  data() {
    return {}
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...authMethods,
  },
  created() {
    this.$store.dispatch('company/getCurrentCompany')
  },
  beforeDestroy() {
    longPulling.stop('auth/updateUserInfo')
  },
  watch: {
    currentUser: {
      deep: true,
      immediate: true,
      handler(user, oldUser) {
        if (user.idRegistrationStatus >= 2) {
          this.$router.go({ name: 'home' })
        }
      },
    },
  },
}
</script>

<style></style>
